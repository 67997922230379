// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-convert-csv-json-tsx": () => import("./../../../src/pages/convert/csv-json.tsx" /* webpackChunkName: "component---src-pages-convert-csv-json-tsx" */),
  "component---src-pages-convert-index-tsx": () => import("./../../../src/pages/convert/index.tsx" /* webpackChunkName: "component---src-pages-convert-index-tsx" */),
  "component---src-pages-convert-json-csv-tsx": () => import("./../../../src/pages/convert/json-csv.tsx" /* webpackChunkName: "component---src-pages-convert-json-csv-tsx" */),
  "component---src-pages-convert-json-xml-tsx": () => import("./../../../src/pages/convert/json-xml.tsx" /* webpackChunkName: "component---src-pages-convert-json-xml-tsx" */),
  "component---src-pages-convert-xml-json-tsx": () => import("./../../../src/pages/convert/xml-json.tsx" /* webpackChunkName: "component---src-pages-convert-xml-json-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

